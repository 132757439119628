/*
 * File: constants.js
 * Project: frontend
 * File Created: Monday, 1st May 2023 5:07:21 pm
 * Author: Sharath Puranik (sharath.puranik@stylumia.com)
 * -----
 * Last Modified: Sunday, 1st September 2024 11:38:15 pm
 * Modified By: Sharath Puranik (sharath.puranik@stylumia.com>)
 * -----
 * Copyright - 2024, Stylumia Intelligence Technology Pvt Ltd
 */
export const redirectMain = "https://cit.stylumia.com/";
export const login_endpoint = "/api/login/magic";
export const validate_endpoint = "/api/login/magic/validate";
export const password_endpoint = "/api/login/password";
export const password_forgot_endpoint = "/api/login/password/request_reset";
export const password_reset_endpoint = "/api/login/password/reset";
export const password_validate = "/api/login/password/validate";
