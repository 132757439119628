import React, { PureComponent } from "react";

import Carousel from "../../Carousel/Carousel";

import Header from "./header";
import Footer from "./footer";

import "./template.css";
import "./media.css";

class Template extends PureComponent {
  render() {
    return (
      <div className="body-container">
        <div className="left-body-container">
          <Header
            source={this.props.source}
            title={this.props.text}
            subText={this.props.subText}
          />
          <div className="login-filler-place">{this.props.children}</div>
          <div>
            <Footer />
          </div>
        </div>
        <div className="right-body-container">
          <div className="right-container-announcement-box">
            <div className="announcement-size-container-box">
              <div className="announcemnt-header-text">
                {" "}
                Thought Starters for the Future of Retail
              </div>
              <Carousel />
              {/* <div className='announcemnt-header-subtext'>
                Learn how to make customer right products with data-driven demand insights.
              </div>
              <a rel='noopener noreferrer' target='_blank' href='https://www.stylumia.ai/blog/'>
                <div className='announcemnt-header-action-button'>Read our Blogs</div>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Template;
