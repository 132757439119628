/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./Carousel.css";
import axios from "axios";

import { usersAuthLog } from "../../utils/loginLogs";

export default function Carousel() {
  const [imgArr, setImgArr] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleSlideChange = () => {
    const fingerID = localStorage.getItem("fingerId");
    const authLogObj = {
      serviceCategory: "Auth",
      service: "Login",
      eventCategory: "Login RHS",
      eventAction: "Carousel Button Press",
      eventLabel: "Carousel Button Clicked",
      eventValue: {
        fingerprint: fingerID,
      },
    };

    usersAuthLog(authLogObj);
  };

  const handleBlogClick = (title, id, blogUrl) => {
    const fingerID = localStorage.getItem("fingerId");
    const authLogObj = {
      serviceCategory: "Auth",
      service: "Login",
      eventCategory: "Login RHS",
      eventAction: "Blog Clicked",
      eventLabel: "Blog Viewed",
      eventValue: {
        title,
        blog_id: id,
        blog_url: blogUrl,
        fingerprint: fingerID,
      },
    };

    usersAuthLog(authLogObj);
  };

  const handleExcerptClick = (e, title, id, blogUrl) => {
    const elem = e.target.closest("a");
    if (elem) {
      const fingerID = localStorage.getItem("fingerId");
      const authLogObj = {
        serviceCategory: "Auth",
        service: "Login",
        eventCategory: "Login RHS",
        eventAction: "Blog Clicked",
        eventLabel: "Blog Viewed",
        eventValue: {
          title,
          blog_id: id,
          blog_url: blogUrl,
          fingerprint: fingerID,
        },
      };

      usersAuthLog(authLogObj);
    }
  };

  useEffect(() => {
    axios
      .get("https://www.stylumia.ai/wp-json/wp/v2/posts?per_page=3")
      .then((res) => {
        setImgArr(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading === true ? (
        <>
          <img
            className="loader"
            height={40}
            src="stylumia_hourglass.gif"
            alt=""
          />
        </>
      ) : (
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={imgArr && imgArr.length}
          visibleSlides={1}
          currentSlide={1}
          className="carousel__container"
        >
          <Slider className="slide_container">
            {imgArr &&
              imgArr.map((item, index) => {
                return (
                  <Slide index={index}>
                    <a
                      onClick={() =>
                        handleBlogClick(item.title.rendered, item.id, item.link)
                      }
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        className="image_container"
                        style={{
                          height: "100%",
                          borderRadius: "10px",
                          backgroundImage: `url(${item.yoast_head_json.og_image[0].url})`,
                        }}
                      />
                    </a>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.title.rendered }}
                      style={{
                        fontSize: "20px",
                        textAlign: "left",
                        color: "white",
                        margin: "10px 0",
                      }}
                    />
                    <div className="sub_text_ttr">
                      Published on: {new Date(item.date).toDateString()}
                    </div>
                    <div
                      onClick={(e) =>
                        handleExcerptClick(
                          e,
                          item.title.rendered,
                          item.id,
                          item.link
                        )
                      }
                      className="excerpt"
                      dangerouslySetInnerHTML={{
                        __html: `${item.excerpt.rendered}<span> <a href=${item.link} target="_blank">Continue reading</a> </span>`,
                      }}
                    />
                  </Slide>
                );
              })}
          </Slider>
          <ButtonBack onClick={handleSlideChange} className="navigation_btn">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17"
                cy="17"
                r="16.5"
                fill="white"
                fillOpacity="0.4"
                stroke="white"
              />
              <path
                d="M20 9L12 17L20 25"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ButtonBack>

          <ButtonNext onClick={handleSlideChange} className="navigation_btn">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17"
                cy="17"
                r="16.5"
                transform="rotate(-180 17 17)"
                fill="white"
                fillOpacity="0.4"
                stroke="white"
              />
              <path
                d="M14 25L22 17L14 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ButtonNext>
        </CarouselProvider>
      )}
    </>
  );
}
