/*
 * File: PasswordResetSuccess.jsx
 * Project: frontend
 * File Created: Monday, 1st May 2023 5:07:21 pm
 * Author: Sharath Puranik (sharath.puranik@stylumia.com)
 * -----
 * Last Modified: Friday, 30th August 2024 1:12:49 am
 * Modified By: Sharath Puranik (sharath.puranik@stylumia.com>)
 * -----
 * Copyright - 2024, Stylumia Intelligence Technology Pvt Ltd
 */

import React, { useEffect, useState } from "react";

import Lottie from "lottie-react";
import { useHistory } from "react-router-dom";

import checkSuccess from "../../animations/check_success.json";

function PasswordResetSuccess(props) {
  const [redirectTimer, setRedirectTimer] = useState(5);

  const history = useHistory();

  useEffect(() => {
    const search = props.location.search;
    const email = new URLSearchParams(search).get("email");
    // const redirect = new URLSearchParams(search).get("redirect");

    sessionStorage.setItem("userEmailPrefill", email);

    const redirectTimerInit = setInterval(() => {
      setRedirectTimer(redirectTimer - 1);

      if (redirectTimer === 0) {
        clearInterval(redirectTimerInit);

        history.push("/");
        // if(redirect){
        //     history.push(`/login/magic?user=${email}&redirect=${redirect}`)
        //   }
        //   else{
        //     history.push(`/login/magic?user=${email}&redirect=${redirectMain}`)
        //   }
      }
    }, 1000);
  }, [redirectTimer]);

  return (
    <>
      <div className="login_unsuccessful">
        {/* <img height={"170px"} src="sad_dog.png" /> */}
        <Lottie style={{ height: "170px" }} animationData={checkSuccess} loop />
        <h2 className="login-page-message-header">
          Your password has been reset successfully.
        </h2>
        <p>
          Redirecting in {redirectTimer} {redirectTimer > 1 ? "secs" : "sec"}{" "}
        </p>
        {/* <br/>
              <button onClick={handleGoToSignIn} className= 'request-again-btn click-ripple-effect'
                style={{backgroundColor: '#479AFF'}}>
                 Sign in
              </button> */}
      </div>
    </>
  );
}

export default PasswordResetSuccess;
