import React from "react";
import Lottie from "lottie-react";

import checkSuccess from "../../animations/check_success.json";

function RedirectToBlog() {
  const search = window.location.hash;
  const paramString = search.split("?")[1];
  const redirect = new URLSearchParams(paramString).get("redirect");
  const handleContinue = () => {
    window.location.assign(redirect);
  };
  return (
    <>
      <div className="login_unsuccessful">
        <Lottie style={{ height: "170px" }} animationData={checkSuccess} loop />
        <h2 className="login-page-message-header">
          You are already logged in. Press the button below to access Consumer
          Intelligence
        </h2>
        <button
          type="button"
          onClick={handleContinue}
          className="request-again-btn click-ripple-effect"
          style={{ backgroundColor: "#479AFF" }}
        >
          Continue
        </button>
      </div>
    </>
  );
}

export default RedirectToBlog;
