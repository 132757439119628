/*
 * File: MagicValidate.js
 * Project: frontend
 * File Created: Tuesday, 29th August 2023 9:45:15 pm
 * Author: Sharath Puranik (sharath.puranik@stylumia.com)
 * -----
 * Last Modified: Friday, 30th August 2024 1:12:44 am
 * Modified By: Sharath Puranik (sharath.puranik@stylumia.com>)
 * -----
 * Copyright - 2024, Stylumia Intelligence Technology Pvt Ltd
 */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Lottie from "lottie-react";

import get_host from "../../utils";
import sadDog from "../../animations/sad-dog.json";
import { usersAuthLog } from "../../utils/loginLogs";
import { validate_endpoint, redirectMain } from "../../constants/constants";

require("dotenv").config();

function MagicValidate(props) {
  const history = useHistory();
  const search = props.location.search;
  const email = new URLSearchParams(search).get("email");
  const token = new URLSearchParams(search).get("token");
  const redirect = new URLSearchParams(search).get("redirect");

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(0);
  const [status_code, setStatusCode] = useState("");
  useEffect(() => {
    const formData = new FormData();
    const fingerID = localStorage.getItem("fingerId");
    formData.append("fingerprint", fingerID);
    formData.append("email", email);
    formData.append("token", token);
    formData.append("redirect", redirect);

    setLoading(1);
    const start = performance.now();
    axios
      .post(get_host(validate_endpoint), formData)
      .then((res) => {
        if (res.status === 200) {
          setStatusCode(res.status);
          setLoading(2);

          const authLogObj = {
            email,
            serviceCategory: "Auth",
            service: "Login",
            eventCategory: "Login Without Password",
            eventAction: "Validate",
            eventLabel: "Magic Login Successful",
            fingerprintID: fingerID,
            eventValue: {
              status_code: res.status,
              time_taken: performance.now() - start,
              fingerprint: fingerID,
            },
          };

          usersAuthLog(authLogObj);
          setTimeout(() => {
            window.location.assign(redirect);
          }, 2000);
        } else {
          const message =
            res.data !== undefined
              ? res.data.detail || res.data.desccription
              : res.message;
          setMessage(
            res.data !== undefined
              ? res.data.detail || res.data.desccription
              : res.message
          );
          setStatusCode(res.status);
          const authLogObj = {
            email,
            serviceCategory: "Auth",
            service: "Login",
            eventCategory: "Login Without Password",
            eventAction: "Validate",
            eventLabel: message,
            fingerprintID: fingerID,
            eventValue: {
              status_code: res.status,
              time_taken: performance.now() - start,
              fingerprint: fingerID,
            },
          };

          usersAuthLog(authLogObj);
        }
      })
      .catch((err) => {
        setLoading(3);
        setTimeout(() => {
          setMessage(err.response.data.detail);
        }, 3000);

        setStatusCode(err.response.status);
        if (err.response.status === 401) {
          const authLogObj = {
            email: this.state.email,
            serviceCategory: "Auth",
            service: "Login",
            eventCategory: "Login Without Password",
            eventAction: "Validate",
            eventLabel: "Magic Link Expired",
            fingerprintID: fingerID,
            eventValue: {
              status_code: err.response.status,
              time_taken: performance.now() - start,
              fingerprint: fingerID,
            },
          };

          usersAuthLog(authLogObj);
        } else if (err.response.status === 409) {
          const authLogObj = {
            email: this.state.email,
            serviceCategory: "Auth",
            service: "Login",
            eventCategory: "Login Without Password",
            eventAction: "Validate",
            eventLabel: "Different Browser Detected",
            fingerprintID: fingerID,
            eventValue: {
              status_code: err.response.status,
              time_taken: performance.now() - start,
              fingerprint: fingerID,
            },
          };

          usersAuthLog(authLogObj);
        } else if (err.response.status === 429) {
          const authLogObj = {
            email: this.state.email,
            serviceCategory: "Auth",
            service: "Login",
            eventCategory: "Login Without Password",
            eventAction: "Validate",
            eventLabel: "Device Limit Reached",
            fingerprintID: fingerID,
            eventValue: {
              status_code: err.response.status,
              time_taken: performance.now() - start,
              fingerprint: fingerID,
            },
          };

          usersAuthLog(authLogObj);
        } else {
          const authLogObj = {
            email,
            serviceCategory: "Auth",
            service: "Login",
            eventCategory: "Login Without Password",
            eventAction: "Validate",
            eventLabel: err.response.data.detail,
            fingerprintID: fingerID,
            eventValue: {
              status_code: err.response.status,
              time_taken: performance.now() - start,
              fingerprint: fingerID,
            },
          };

          usersAuthLog(authLogObj);
        }
      });
  }, []);

  const handleRedirect = () => {
    if (redirect) {
      history.push(`/login/magic?email=${email}&redirect=${redirect}`);
    } else {
      history.push(`/login/magic?email=${email}&redirect=${redirectMain}`);
    }
  };

  const handleGotoHome = () => {
    history.push("/");
  };
  return (
    <>
      {loading === 1 ? (
        <div className="hourglass-loader">
          <img src="filter_hourglass.gif" alt="" />
          <p>Decoding your consumers' True Demand...</p>
        </div>
      ) : message === "OK" && loading === 2 ? (
        <div className="login_successful">
          <img height="170px" src="loginSuccess.gif" alt="" />
          <p>
            You are already logged in. Press the button below to access Consumer
            Intelligence
          </p>
          <button
            type="button"
            onClick={handleRedirect}
            className="request-again-btn click-ripple-effect"
            style={{ backgroundColor: "#479AFF" }}
          >
            Continue
          </button>
        </div>
      ) : (
        loading === 3 && (
          <>
            <div className="header-links">
              <div className="logo-container">
                <img
                  style={{ cursor: "pointer" }}
                  onClick={handleGotoHome}
                  src={require("../../assests/stylumia_logo.svg")}
                  alt=""
                />
              </div>
              <div className="close-button-container">
                <img
                  style={{ cursor: "pointer" }}
                  onClick={handleGotoHome}
                  src="close-button.png"
                  alt=""
                />
              </div>
            </div>
            <div className="login_unsuccessful">
              <Lottie style={{ height: "170px" }} animationData={sadDog} loop />
              {status_code === 409 ? (
                <>
                  <h2 className="login-page-message-header">
                    Different device / browser detected
                  </h2>
                  <p>
                    Please ensure you're opening the link on the same browser /
                    device from where it was requested.
                  </p>
                  <button
                    type="button"
                    onClick={handleRedirect}
                    className="request-again-btn click-ripple-effect"
                    style={{ backgroundColor: "#479AFF" }}
                  >
                    Try Again
                  </button>
                </>
              ) : status_code === 401 ? (
                <>
                  <h2 className="login-page-message-header">
                    Oops, link expired
                  </h2>
                  <p>
                    Kindly request again and click on the link within 15 minutes
                  </p>
                  <p style={{ color: "rgba(124, 124, 150, 1)" }}>{email}</p>
                  <button
                    type="button"
                    onClick={handleRedirect}
                    className="request-again-btn click-ripple-effect"
                    style={{ backgroundColor: "#479AFF" }}
                  >
                    Request Again
                  </button>
                </>
              ) : (
                status_code === 429 && (
                  <>
                    <h2 className="login-page-message-header">
                      You have reached your device limit.{" "}
                    </h2>
                    <p>
                      For resolution, please reach out to your Customer Success
                      Manager.
                    </p>
                  </>
                )
              )}
            </div>
          </>
        )
      )}
    </>
  );
}

export default MagicValidate;
