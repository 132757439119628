/*
 * File: index.js
 * Project: frontend
 * File Created: Wednesday, 3rd April 2024 5:12:28 pm
 * Author: Sharath Puranik (sharath.puranik@stylumia.com)
 * -----
 * Last Modified: Sunday, 1st September 2024 11:54:19 pm
 * Modified By: Sharath Puranik (sharath.puranik@stylumia.com>)
 * -----
 * Copyright - 2024, Stylumia Intelligence Technology Pvt Ltd
 */

import React, { Component } from "react";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import Lottie from "lottie-react";
import axios from "axios";
import Cookies from "js-cookie";

import Input from "../Input/index";
import get_host from "../../utils";
import { redirectMain, login_endpoint } from "../../constants/constants";
import astronaut from "../../animations/baby_astronaut.json";
import { usersAuthLog } from "../../utils/loginLogs";
import Button from "../Button/Button";

import Template from "./template";
import RedirectToBlog from "./RedirectToBlog";
import "./style.css";

require("dotenv").config();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      inputEmailType: "email",
      wrongPassword: false,
      backendBroke: false,
      error: { email: false, text: false },
      finalError: false,
      loading: false,
      err_message: "",
      timer: 30,
      email_sent: false,
      mainLoading: true,
      isLoggedIn: false,
      fingerID: "",
      internalServerError: false,
    };

    this.handlekeyPress = this.handlekeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.requestAgain = this.requestAgain.bind(this);
    this.handleWithPassword = this.handleWithPassword.bind(this);
  }

  handlekeyPress(e) {
    if (e.keyCode === 13 && this.state.email !== "") {
      this.handleSubmit();
    }
  }

  handleWithPassword() {
    sessionStorage.setItem("userEmailId", this.state.email);
    const search = this.props.location.search;
    const redirect = new URLSearchParams(search).get("redirect");

    if (redirect) {
      this.props.history.push(`/login/password?redirect=${redirect}`);
    } else {
      this.props.history.push(`/login/password?redirect=${redirectMain}`);
    }
  }

  componentDidMount() {
    const search = this.props.location.search;
    const email = new URLSearchParams(search).get("email");
    const user = sessionStorage.getItem("userEmailPrefill");

    const jwtToken = Cookies.get("jwt_session");

    if (jwtToken) {
      this.setState({
        isLoggedIn: true,
        mainLoading: false,
      });
    }

    if (user) {
      this.setState({
        email: user,
        mainLoading: false,
      });
    }

    if (email) {
      this.setState({
        email,
        mainLoading: false,
      });
    } else {
      setTimeout(() => {
        this.setState({
          mainLoading: false,
        });
      }, 800);
    }
  }

  requestAgain() {
    this.setState({
      timer: 30,
      loading: false,
      email_sent: false,
    });

    if (this.state.fingerID !== "") {
      const start = performance.now();
      const authLogObj = {
        email: this.state.email,
        serviceCategory: "Auth",
        service: "Login",
        eventCategory: "Passwordless Login",
        eventAction: "Click",
        eventLabel: "Request Link Again",
        fingerprintID: this.state.fingerID,
        eventValue: {
          time_taken: performance.now() - start,
        },
      };

      usersAuthLog(authLogObj);

      this.handleSubmit();
    }
  }

  handleSubmit() {
    const search = this.props.location.search;
    const redirect =
      new URLSearchParams(search).get("redirect") || redirectMain;

    sessionStorage.setItem("userEmailId", this.state.email);

    this.props
      .fpGetData()
      .then((fingerprintData) => {
        //  change below this as per fetch, do a check for res.okay
        // run_auth(fingerprintData);
        const data = fingerprintData;
        localStorage.setItem("fingerId", data.visitorId);
        if (data && data.visitorId && this.state.email) {
          const formData = new FormData();
          formData.append("email", this.state.email);
          formData.append("redirect", redirect);
          formData.append("fingerprint", data.visitorId);

          const authLogObjOne = {
            email: this.state.email,
            serviceCategory: "Auth",
            service: "Login",
            eventCategory: "Passwordless Login",
            eventAction: "Submit",
            eventLabel: "User Entered Details",
            fingerprintID: data.visitorId,
            eventValue: {
              email: this.state.email,
              fingerprint: data.visitorId,
            },
          };

          usersAuthLog(authLogObjOne);

          this.setState({
            loading: true,
            fingerID: data.visitorId,
          });
          const start = performance.now();
          axios
            .post(get_host(login_endpoint), formData)
            .then((res) => {
              if (res.status === 200) {
                this.setState({
                  email_sent: true,
                  err_message: "",
                  loading: false,
                });

                const timerCounter = setInterval(() => {
                  this.setState((prevState) => ({
                    timer: prevState.timer - 1,
                  }));

                  if (this.state.timer === 0) {
                    clearInterval(timerCounter);
                  }
                }, 1000);

                const authLogObj = {
                  email: this.state.email,
                  serviceCategory: "Auth",
                  service: "Login",
                  eventCategory: "Passwordless Login",
                  eventAction: "Submit",
                  eventLabel: "Magic Link Sent",
                  fingerprintID: data.visitorId,
                  eventValue: {
                    status_code: res.status,
                    time_taken: performance.now() - start,
                    fingerprint: data.visitorId,
                  },
                };

                usersAuthLog(authLogObj);
              }
            })
            .catch((err) => {
              const err_log_message =
                err.response && err.response.data !== undefined
                  ? err.response.data.detail || err.response.data.desccription
                  : err.message;
              const authLogObj = {
                email: this.state.email,
                serviceCategory: "Auth",
                service: "Login",
                eventCategory: "Passwordless Login",
                eventAction: "Submit",
                fingerprintID: data.visitorId,
                eventValue: {
                  status_code: err.response.status,
                  time_taken: performance.now() - start,
                  fingerprint: data.visitorId,
                },
              };

              if (err.response.status === 500) {
                this.setState({
                  internalServerError: true,
                });

                authLogObj.eventLabel = "Internal Server Error";
                usersAuthLog(authLogObj);
              } else if (err.response.status === 404) {
                authLogObj.eventLabel = "Invalid Email";
                usersAuthLog(authLogObj);
              } else {
                authLogObj.eventLabel = err_log_message;
                usersAuthLog(authLogObj);
              }

              this.setState({
                err_message:
                  err.response && err.response.data !== undefined
                    ? err.response.data.detail || err.response.data.desccription
                    : err.message,
                loading: false,
              });
            });
        }
      })
      .catch((error) => {
        // console.log(error)
        const fingerprintData = { visitorId: "NA" };
        // run_auth(fingerprintData);
      });
  }

  getEmail({ value }) {
    this.setState({ email: value, err_message: "" });
  }

  handleError = (errorState, er) => {
    let x;
    this.setState(
      (state) => {
        x = state.error;
        x[errorState] = er;
        return { error: x };
      },
      () => {
        let flag;
        for (x in this.state.error) {
          if (this.state.error[x]) {
            flag = true;
          }
        }
        if (flag === true) {
          this.setState({ finalError: true });
        } else {
          this.setState({ finalError: false });
        }
      }
    );
  };

  render() {
    const backendBroke =
      "Something seems to be broken. Please try after some time.";
    const errorText =
      "There was an error with your e-mail/password combination. Please try again.";

    return this.state.isLoggedIn ? (
      <RedirectToBlog />
    ) : this.state.mainLoading ? (
      ""
    ) : (
      <Template>
        <div className="login-welcome-container" style={{ display: "grid" }}>
          <div className="header-container">
            <span className="login-welcome-text">
              Welcome to Consumer Intelligence
            </span>
          </div>
          <div className="" style={{ maxWidth: "368px", width: "100%" }}>
            {this.state.backendBroke ? (
              <span className="error-msg" style={{ textAlign: "start" }}>
                {backendBroke}
              </span>
            ) : (
              ""
            )}
            {this.state.wrongPassword ? (
              <span className="error-msg" style={{ textAlign: "start" }}>
                {errorText}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <br />
        <div onKeyDown={this.handlekeyPress}>
          <Input
            className="input.button"
            leftChild={(() => (
              <img
                alt=""
                className="icon"
                src={require("./../../assests/mail.svg")}
              />
            ))()}
            label="EMAIL"
            placeholder="Enter your work email address"
            value={this.state.email}
            onChange={(e) => this.getEmail}
            validation="email"
            errorMessage="Enter a valid email address"
            getInputValue={this.getEmail}
            error={this.state.error}
            disabled={!!this.state.email_sent}
            handleError={this.handleError}
            type={this.state.inputEmailType}
            name={this.state.inputEmailType}
          />
          {this.state.err_message && (
            <small style={{ color: "red" }}>{this.state.err_message}</small>
          )}
          {this.state.internalServerError === true && (
            <small style={{ color: "red" }}> Internal server error </small>
          )}
          {this.state.email_sent ? (
            <div className="check_email_container">
              <Lottie
                style={{ height: "111px" }}
                animationData={astronaut}
                loop
              />
              <div className="check_email">Check Email!!</div>
              <div className="signed_in_for_two_weeks">
                Click on the link and stay signed in for 1 week. You'll be
                logged in on a new tab - feel free to close this tab or check
                out our latest thought-starters.
              </div>
              <br />
              <div className="signed_in_for_two_weeks">
                {" "}
                <b> Note:</b> Check your spam/junk folder if the email doesn't
                arrive in your inbox.
              </div>
              <br />
              <div className="signed_in_for_two_weeks">
                {" "}
                New link in {this.state.timer}{" "}
                {this.state.timer > 1 ? "secs" : "sec"}{" "}
                {this.state.timer === 0 && (
                  <span
                    onClick={this.requestAgain}
                    style={{
                      color: "rgba(71, 154, 255, 1)",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    Request Now{" "}
                  </span>
                )}{" "}
              </div>
            </div>
          ) : (
            <>
              <div className="options-buttons-container">
                <div className="login-button without-password">
                  {this.state.loading ? (
                    <button
                      type="button"
                      className="inactive-button"
                      disabled
                      style={{ backgroundColor: "#479AFF" }}
                    >
                      Verifying...
                    </button>
                  ) : this.state.finalError ? (
                    <button type="button" className="inactive-button">
                      Without Password
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={this.handleSubmit}
                      className={
                        this.state.email !== ""
                          ? "without-password click-ripple-effect"
                          : "inactive-button"
                      }
                      disabled={this.state.email === ""}
                      style={{
                        backgroundColor:
                          this.state.email === "" ? "grey" : "#479AFF",
                      }}
                    >
                      Without Password
                    </button>
                  )}
                </div>
                <div className="login-button with-password">
                  <Button
                    className={
                      this.state.loading
                        ? "inactive-button"
                        : "click-ripple-effect"
                    }
                    onClick={this.handleWithPassword}
                    style={{ backgroundColor: "white" }}
                    label="With Password" // {this.state.loading ? 'Logging In...' : 'Login'}
                    minWidth="200px"
                    width="100%"
                    textColor={
                      this.state.email === ""
                        ? "rgba(0,0,0,0.5)"
                        : "rgba(0,0,0,1)"
                    }
                    backgroundColor="white"
                    border="1px solid rgba(124, 124, 150, 0.28)"
                    error={false}
                    borderRadius="5px"
                    emailPresent
                    disabledBtn={!!this.state.loading}
                  />
                </div>
              </div>
              <div className="never-remember-password">
                Never remember a password again
              </div>
            </>
          )}
        </div>
        <div className="login-contact-us-container">
          <div>
            New to Stylumia?&nbsp;{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.stylumia.ai/get-a-demo/"
            >
              {" "}
              Sign up for a demo{" "}
            </a>
          </div>
        </div>
      </Template>
    );
  }
}

function CallApi(Component) {
  return function WrappedComponent(props) {
    const { getData } = useVisitorData(
      { extended: true },
      { immediate: false }
    );
    return <Component {...props} fpGetData={getData} />;
  };
}

export default CallApi(Login);
