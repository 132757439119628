/*
 * File: WithPassword.jsx
 * Project: frontend
 * File Created: Monday, 1st May 2023 5:07:21 pm
 * Author: Sharath Puranik (sharath.puranik@stylumia.com)
 * -----
 * Last Modified: Friday, 30th August 2024 1:13:02 am
 * Modified By: Sharath Puranik (sharath.puranik@stylumia.com>)
 * -----
 * Copyright - 2024, Stylumia Intelligence Technology Pvt Ltd
 */

import React, { Component } from "react";

import "./style.css";
import axios from "axios";

import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

import Input from "../Input/index";

import { password_endpoint } from "../../constants/constants";
import get_host from "../../utils";

import { usersAuthLog } from "../../utils/loginLogs";

import Template from "./template";

class LoginWithPassword extends Component {
  constructor(props) {
    super(props);

    const search = props.location.search;
    const redirect = new URLSearchParams(search).get("redirect");

    this.state = {
      email: "",
      password: "",
      redirect,
      inputPasswordType: "password",
      inputEmailType: "email",
      inputPasswordIcon: "showPassword.svg",
      wrongPassword: false,
      backendBroke: false,
      error: { email: false, text: false },
      finalError: false,
      loading: false,
      err_message: "",
      internalServerError: false,
    };

    this.handlekeyPress = this.handlekeyPress.bind(this);
    this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.getPassword = this.getPassword.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.handleGoToMainLogin = this.handleGoToMainLogin.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  handlekeyPress(e) {
    if (e.keyCode === 13) {
      this.handleSubmitLogin(e);
    }
  }

  componentWillMount() {
    const reqEmail = sessionStorage.getItem("userEmailId");

    if (reqEmail !== "") {
      this.setState({
        email: reqEmail,
      });
    }
  }

  handleGoToMainLogin() {
    this.props.history.push(`/?redirect=${this.state.redirect}`);
  }

  handleResetPassword() {
    this.props.history.push(`/login/forgot?redirect=${this.state.redirect}`);
  }

  handleSubmitLogin(event) {
    this.props
      .fpGetData()
      .then((fingerprintData) => {
        const data = fingerprintData;
        if (data && data.visitorId && this.state.email && this.state.password) {
          const formData = new FormData();
          formData.append("email", this.state.email);
          formData.append("redirect", this.state.redirect);
          formData.append("password", this.state.password);
          formData.append("fingerprint", data.visitorId);

          const authLogObjOne = {
            email: this.state.email,
            serviceCategory: "Auth",
            service: "Login",
            eventCategory: "Login With Password",
            eventAction: "Submit",
            eventLabel: "User Entered Details",
            fingerprintID: data.visitorId,
            eventValue: {
              email: this.state.email,
              fingerprint: data.visitorId,
            },
          };

          usersAuthLog(authLogObjOne);

          this.setState({
            loading: true,
          });
          const start = performance.now();
          axios
            .post(get_host(password_endpoint), formData)
            .then((res) => {
              // console.log(res);
              if (res.status === 200) {
                window.location = this.state.redirect;
                this.setState({
                  loading: false,
                });

                const authLogObj = {
                  email: this.state.email,
                  serviceCategory: "Auth",
                  service: "Login",
                  eventCategory: "Login With Password",
                  eventAction: "Submit",
                  eventLabel: "Login With Password Successful",
                  fingerprintID: data.visitorId,
                  eventValue: {
                    status_code: res.status,
                    time_taken: performance.now() - start,
                    fingerprint: data.visitorId,
                  },
                };

                usersAuthLog(authLogObj);
              } else {
                this.setState({
                  err_message:
                    res.data !== undefined
                      ? res.data.detail || res.data.desccription
                      : res.message,
                  loading: false,
                });

                const authLogObj = {
                  email: this.state.email,
                  serviceCategory: "Auth",
                  service: "Login",
                  eventCategory: "Login With Password",
                  eventAction: "Submit",
                  eventLabel:
                    res.data !== undefined
                      ? res.data.detail || res.data.desccription
                      : res.message,
                  fingerprintID: data.visitorId,
                  eventValue: {
                    status_code: res.status,
                    time_taken: performance.now() - start,
                    fingerprint: data.visitorId,
                  },
                };

                usersAuthLog(authLogObj);
              }
            })
            .catch((err) => {
              if (err.response.status === 500) {
                this.setState({
                  internalServerError: true,
                });

                const authLogObj = {
                  email: this.state.email,
                  serviceCategory: "Auth",
                  service: "Login",
                  eventCategory: "Login With Password",
                  eventAction: "Submit",
                  eventLabel: "Internal Server Error",
                  fingerprintID: data.visitorId,
                  eventValue: {
                    status_code: err.response.status,
                    time_taken: performance.now() - start,
                    fingerprint: data.visitorId,
                  },
                };

                usersAuthLog(authLogObj);
              } else if (err.response.status === 417) {
                const authLogObj = {
                  email: this.state.email,
                  serviceCategory: "Auth",
                  service: "Login",
                  eventCategory: "Login With Password",
                  eventAction: "Submit",
                  eventLabel: "First Time Password Reset Required",
                  fingerprintID: data.visitorId,
                  eventValue: {
                    status_code: err.response.status,
                    time_taken: performance.now() - start,
                    fingerprint: data.visitorId,
                  },
                };

                usersAuthLog(authLogObj);
              } else if (err.response.status === 401) {
                const authLogObj = {
                  email: this.state.email,
                  serviceCategory: "Auth",
                  service: "Login",
                  eventCategory: "Login With Password",
                  eventAction: "Submit",
                  eventLabel: "Invalid Email or Password",
                  fingerprintID: data.visitorId,
                  eventValue: {
                    status_code: err.response.status,
                    time_taken: performance.now() - start,
                    fingerprint: data.visitorId,
                  },
                };

                usersAuthLog(authLogObj);
              } else if (err.response.status === 429) {
                const authLogObj = {
                  email: this.state.email,
                  serviceCategory: "Auth",
                  service: "Login",
                  eventCategory: "Login With Password",
                  eventAction: "Submit",
                  eventLabel: "Device Limit Reached",
                  fingerprintID: data.visitorId,
                  eventValue: {
                    status_code: err.response.status,
                    time_taken: performance.now() - start,
                    fingerprint: data.visitorId,
                  },
                };

                usersAuthLog(authLogObj);
              } else {
                const authLogObj = {
                  email: this.state.email,
                  serviceCategory: "Auth",
                  service: "Login",
                  eventCategory: "Login With Password",
                  eventAction: "Submit",
                  eventLabel:
                    err.response && err.response.data !== undefined
                      ? err.response.data.detail ||
                        err.response.data.desccription
                      : err.message,
                  fingerprintID: data.visitorId,
                  eventValue: {
                    status_code: err.response.status,
                    time_taken: performance.now() - start,
                    fingerprint: data.visitorId,
                  },
                };

                usersAuthLog(authLogObj);
              }
              this.setState({
                err_message:
                  err.response && err.response.data !== undefined
                    ? err.response.data.detail || err.response.data.desccription
                    : err.message,
                loading: false,
              });
            });
        }
      })
      .catch((error) => {
        const fingerprintData = { visitorId: "NA" };
        // run_auth(fingerprintData);
      });
  }

  showPassword() {
    if (this.state.inputPasswordType === "password") {
      this.setState({
        inputPasswordType: "text",
        inputPasswordIcon: "show-password-enabled.svg",
      });
    } else {
      this.setState({
        inputPasswordType: "password",
        inputPasswordIcon: "showPassword.svg",
      });
    }
  }

  getEmail({ value }) {
    this.setState({ email: value, err_message: "" });
  }

  getPassword({ value }) {
    this.setState({ password: value, err_message: "" });
  }

  handleError = (errorState, er) => {
    let x;
    this.setState(
      (state) => {
        x = state.error;
        x[errorState] = er;
        return { error: x };
      },
      () => {
        let flag;
        for (x in this.state.error) {
          if (this.state.error[x]) {
            flag = true;
          }
        }
        if (flag === true) {
          this.setState({ finalError: true });
        } else {
          this.setState({ finalError: false });
        }
      }
    );
  };

  render() {
    const backendBroke =
      "Something seems to be broken. Please try after some time.";
    const errorText =
      "There was an error with your e-mail/password combination. Please try again.";
    return (
      <Template>
        <div className="login-welcome-container" style={{ display: "grid" }}>
          <div className="header-container">
            <span className="login-welcome-text">
              Welcome to Consumer Intelligence
            </span>
          </div>
          <div className="" style={{ maxWidth: "368px", width: "100%" }}>
            {this.state.backendBroke ? (
              <span className="error-msg" style={{ textAlign: "start" }}>
                {backendBroke}
              </span>
            ) : (
              ""
            )}
            {this.state.wrongPassword ? (
              <span className="error-msg" style={{ textAlign: "start" }}>
                {errorText}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <br />
        <div /* style={{width:"95%"}} */ onKeyDown={this.handlekeyPress}>
          <Input
            className="input.button"
            leftChild={(() => (
              <img
                alt=""
                className="icon"
                src={require("./../../assests/mail.svg")}
              />
            ))()}
            label="EMAIL"
            placeholder="Enter your work email address"
            value={this.state.email}
            onChange={(e) => this.handleChangeemail(e)}
            validation="email"
            errorMessage="Enter a valid email address"
            getInputValue={this.getEmail}
            type={this.state.inputEmailType}
            name={this.state.inputEmailType}
            error={this.state.error}
            handleError={this.handleError}
          />
          <Input
            leftChild={(() => (
              <img
                alt=""
                className="icon"
                src={require("./../../assests/lock.png")}
              />
            ))()}
            rightChild={(() => (
              <img
                alt=""
                onClick={this.showPassword}
                className="icon show-password"
                src={require(`./../../assests/${this.state.inputPasswordIcon}`)}
              />
            ))()}
            label="PASSWORD"
            placeholder="Enter your password"
            value={this.state.password}
            onChange={(e) => this.handleChangepassword(e)}
            validation="text"
            errorMessage="Password should be atleast 5 characters long"
            getInputValue={this.getPassword}
            type={this.state.inputPasswordType}
            name={this.state.inputPasswordType}
            error={this.state.error}
            handleError={this.handleError}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                this.handleSubmitLogin();
              }
            }}
          />
          {this.state.err_message && (
            <small style={{ color: "red" }}>{this.state.err_message}</small>
          )}
          {this.state.internalServerError === true && (
            <small style={{ color: "red" }}> Internal server error </small>
          )}

          <div className="login-button ">
            {this.state.loading ? (
              <button
                type="button"
                className="inactive-button"
                disabled
                style={{ backgroundColor: "#479AFF" }}
              >
                {/* <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div> */}
                Logging in...
              </button>
            ) : this.state.finalError ? (
              <button type="button" className="inactive-button">
                Login
              </button>
            ) : (
              <button
                type="button"
                className={
                  this.state.email !== "" || this.state.password !== ""
                    ? "without-password click-ripple-effect"
                    : "inactive-button"
                }
                disabled={
                  !(this.state.email !== "" || this.state.password !== "")
                }
                style={{
                  backgroundColor:
                    this.state.email === "" || this.state.password === ""
                      ? "grey"
                      : "#479AFF",
                }}
                onClick={this.handleSubmitLogin}
              >
                Login
              </button>
            )}
            {/* <Button
              className= {this.state.email !== "" && this.state.password !== "" ? 'click-ripple-effect': "inactive-button"}
              onClick={this.handleSubmitLogin}
              label={this.state.loading ? 'Logging In...' : 'Login'}
              minWidth='200px'
              width='100%'
              error={this.state.finalError}
              border={"none"}
              backgroundColor={"#479AFF"}
              textColor={"white"}
              borderRadius={"5px"}
            /> */}
          </div>
          <div className="forgot-password">
            <span className="show-password" onClick={this.handleResetPassword}>
              Forgot password
            </span>
          </div>

          <button
            type="button"
            disabled={!!this.state.loading}
            onClick={this.handleGoToMainLogin}
            className={
              this.state.loading
                ? "inactive-button-password"
                : "go-passwordless"
            }
          >
            <img style={{ marginRight: "10px" }} src="stars.svg" alt="" />
            Go Passwordless
            <span> Never remember a password again</span>
          </button>
        </div>
        <div className="login-contact-us-container">
          <div>
            New to Stylumia?&nbsp;{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.stylumia.ai/get-a-demo/"
            >
              {" "}
              Sign up for a demo{" "}
            </a>
          </div>
        </div>
      </Template>
    );
  }
}

function CallApi(Component) {
  return function WrappedComponent(props) {
    const { getData } = useVisitorData(
      { extended: true },
      { immediate: false }
    );
    return <Component {...props} fpGetData={getData} />;
  };
}

export default CallApi(LoginWithPassword);
