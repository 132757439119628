/*
 * File: MainRedirect.jsx
 * Project: frontend
 * File Created: Monday, 1st May 2023 5:07:21 pm
 * Author: Sharath Puranik (sharath.puranik@stylumia.com)
 * -----
 * Last Modified: Thursday, 29th August 2024 11:41:07 pm
 * Modified By: Sharath Puranik (sharath.puranik@stylumia.com>)
 * -----
 * Copyright - 2024, Stylumia Intelligence Technology Pvt Ltd
 */

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { redirectMain } from "../../constants/constants";

function MainRedirect(props) {
  const history = useHistory();

  useEffect(() => {
    const search = props.location.search;
    const redirect = new URLSearchParams(search).get("redirect");

    if (redirect) {
      history.push(`/login/magic?redirect=${redirect}`);
    } else {
      history.push(`/login/magic?redirect=${redirectMain}`);
    }
  }, []);

  return <></>;
}

export default MainRedirect;
