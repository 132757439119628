import React, { Component } from "react";

export default class Button extends Component {
  style = {
    button: {
      height: "48px",
      border: this.props.border,
      textAlign: "center",
      borderRadius: this.props.borderRadius,
      textDecoration: "none",
      fontSize: "16px",
      margin: "0px auto",
      backgroundColor: this.props.backgroundColor, // '#479aff',
      color: this.props.textColor,
      minWidth: this.props.minwidth,
      width: this.props.width,
      float: this.props.float ? this.props.float : "",
    },
    buttonError: {
      height: "48px",
      border: this.props.border,
      textAlign: "center",
      textDecoration: "none",
      fontSize: "16px",
      margin: "0px auto",
      borderRadius: this.props.borderRadius,
      backgroundColor: "grey",
      color: this.props.textColor,
      minWidth: this.props.minwidth,
      width: this.props.width,
      cursor: "not-allowed",
      float: this.props.float ? this.props.float : "",
    },
  };

  render() {
    return (
      <div>
        {this.props.error && (
          <button
            type="button"
            className={this.props.className}
            onClick={(e) => {
              e.preventDefault();
            }}
            style={this.style.buttonError}
            disabled={this.props.disabledBtn}
          >
            {this.props.label}
          </button>
        )}
        {!this.props.error && (
          <button
            type="button"
            className={this.props.className}
            onClick={(e) => {
              if (this.props.onClick) this.props.onClick(e);
            }}
            style={this.style.button}
            disabled={this.props.disabledBtn}
          >
            {this.props.label}
          </button>
        )}
      </div>
    );
  }
}
