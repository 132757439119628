/*
 * File: Routes.jsx
 * Project: frontend
 * File Created: Monday, 1st May 2023 5:07:21 pm
 * Author: Sharath Puranik (sharath.puranik@stylumia.com)
 * -----
 * Last Modified: Thursday, 29th August 2024 11:43:23 pm
 * Modified By: Sharath Puranik (sharath.puranik@stylumia.com>)
 * -----
 * Copyright - 2024, Stylumia Intelligence Technology Pvt Ltd
 */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../components/login";
import ForgotPassword from "../components/login/ForgotPassword";
import MagicValidate from "../components/login/MagicValidate";
import MainRedirect from "../components/login/MainRedirect";
import PasswordResetSuccess from "../components/login/PasswordResetSuccess";
import ResetPassword from "../components/login/ResetPassword";
import LoginWithPassword from "../components/login/WithPassword";

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" render={(props) => <MainRedirect {...props} />} />
        <Route
          path="/login/magic/validate"
          render={(props) => <MagicValidate {...props} />}
        />
        <Route path="/login/magic" render={(props) => <Login {...props} />} />
        <Route
          path="/login/password/reset"
          render={(props) => <ResetPassword {...props} />}
        />
        <Route
          path="/login/password"
          render={(props) => <LoginWithPassword {...props} />}
        />
        <Route
          path="/login/forgot"
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          path="/login/password-reset-success"
          render={(props) => <PasswordResetSuccess {...props} />}
        />
      </Switch>
    );
  }
}

export default Routes;
