/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import "./index.css";

class Input extends Component {
  // change container border color
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || "",
      error: false,
      inFocus: false,
    };
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.validate = this.validate.bind(this);
  }

  onFocusHandler(e) {
    this.setState({ inFocus: true });
  }

  validateInput(value) {
    const error = this.validate(value);
    if (
      this.props.validation === "email" ||
      this.props.validation === "text" ||
      this.props.validation === "mobile" ||
      this.props.validation === "none"
    ) {
      this.props.handleError(this.props.validation, error);
    }
    this.setState({ error, inFocus: false });
  }

  onChangeHandler(e) {
    const value = e.target.value;
    if (this.state.error === undefined) {
      this.props.getInputValue({ value, error: false });
      this.setState({ value, error: false });
    } else {
      this.props.getInputValue({ value, error: this.state.error });
      this.setState((state) => ({ value, error: state.error, inFocus: true }));
    }
    this.validateInput(value);
  }

  onBlurHandler(e) {
    if (e.shiftKey || e.altKey || e.ctrlKey || e.metaKey) {
      this.setState({ inFocus: true });
    } else {
      this.validateInput();
    }
  }

  validate(value) {
    if (!value) {
      value = this.state.value;
    }
    if (value === "") {
      return true;
    }
    if (this.props.validation === "text") {
      return !(this.state.value.length > 4);
    }
    if (this.props.validation === "email") {
      return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,13})+$/.test(value);
    }
    if (this.props.validation === "mobile") {
      return !/^(\+\d{1,3}[- ]?)?\d{10}$/.test(value);
    }
    if (this.props.validation === "none") {
      return !/^[^-\s][\w\s-]+$/.test(value);
    }
  }

  render() {
    let containerBorder = { border: "solid 1px rgba(34, 33, 51, 0.12)" };
    let errorDisplay = "none";
    let containerHeight = "95px";
    if (this.state.inFocus) {
      containerBorder = { border: "solid 1px rgb(71, 154, 255)" };
    }
    if (this.state.error && !this.state.inFocus) {
      containerBorder = { border: "solid 1px #eb6c6c" };
      errorDisplay = "block";
      containerHeight = "105px";
    }
    const { leftChild, rightChild } = this.props;
    let gridTemplateColumns = { gridTemplateColumns: "auto" };
    if (leftChild && !rightChild) {
      gridTemplateColumns = { gridTemplateColumns: "50px auto" };
    } else if (!leftChild && rightChild) {
      gridTemplateColumns = { gridTemplateColumns: "auto 50px" };
    } else if (leftChild && rightChild) {
      gridTemplateColumns = { gridTemplateColumns: "50px auto 50px" };
    }

    return (
      <div className="main-input-container" style={{ height: containerHeight }}>
        <div className="login-input-label">{this.props.label}</div>
        <div
          className="input-container"
          style={
            this.props.leftChildStyle
              ? {
                  ...containerBorder,
                  ...this.props.leftChildStyle,
                  ...gridTemplateColumns,
                }
              : { ...containerBorder, ...gridTemplateColumns }
          }
        >
          {this.props.leftChild ? <div>{this.props.leftChild}</div> : ""}

          <div className="input-parent">
            <input
              data-hj-allow
              className="input"
              value={this.state.value}
              onChange={this.props.isInactive ? "" : this.onChangeHandler}
              onKeyPress={(e) => {
                if (this.props.onKeyPress) this.props.onKeyPress(e);
              }}
              readOnly={!!this.props.isInactive}
              onBlur={this.onBlurHandler}
              onFocus={this.onFocusHandler}
              onKeyDown={this.onBlurHandler}
              placeholder={this.props.placeholder}
              type={this.props.type}
              name={this.props.name}
              disabled={this.props.disabled}
              style={{ cursor: this.props.isInactive ? "not-allowed" : "" }}
            />
          </div>
          {this.props.rightChild ? <div>{this.props.rightChild}</div> : ""}
        </div>
        {this.state.inFocus === false && (
          <div className="error-msg" style={{ display: errorDisplay }}>
            {this.props.errorMessage}
          </div>
        )}
      </div>
    );
  }
}

export default Input;
