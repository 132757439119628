/*
 * File: ForgotPassword.jsx
 * Project: frontend
 * File Created: Monday, 1st May 2023 5:07:21 pm
 * Author: Sharath Puranik (sharath.puranik@stylumia.com)
 * -----
 * Last Modified: Friday, 30th August 2024 1:05:57 am
 * Modified By: Sharath Puranik (sharath.puranik@stylumia.com>)
 * -----
 * Copyright - 2024, Stylumia Intelligence Technology Pvt Ltd
 */
import React, { Component } from "react";

import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import axios from "axios";
import Lottie from "lottie-react";

import {
  redirectMain,
  password_forgot_endpoint,
} from "../../constants/constants";
import Input from "../Input/index";
import astronaut from "../../animations/baby_astronaut.json";
import get_host from "../../utils";
import { usersAuthLog } from "../../utils/loginLogs";

import Template from "./template";
import "./style.css";

require("dotenv").config();

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      backendBroke: false,
      error: { email: false, text: false },
      finalError: false,
      loading: false,
      err_message: "",
      email_sent: false,
      inputEmailType: "email",
    };

    this.handlekeyPress = this.handlekeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getEmail = this.getEmail.bind(this);
  }

  handlekeyPress(e) {
    if (e.keyCode === 13 && this.state.email !== "") {
      this.handleSubmit();
    }
  }

  handleSubmit() {
    const search = this.props.location.search;
    const redirect =
      new URLSearchParams(search).get("redirect") || redirectMain;

    sessionStorage.setItem("userEmailId", this.state.email);

    this.props.fpGetData().then((fingerprintData) => {
      const data = fingerprintData;
      localStorage.setItem("fingerId", data.visitorId);
      if (data && data.visitorId && this.state.email) {
        const formData = new FormData();
        formData.append("email", this.state.email);
        formData.append("redirect", redirect);
        formData.append("fingerprint", data.visitorId);

        const authLogObjOne = {
          email: this.state.email,
          serviceCategory: "Auth",
          service: "Login",
          eventCategory: "Forgot Password",
          eventAction: "Submit",
          eventLabel: "User Entered Details",
          fingerprintID: data.visitorId,
          eventValue: {
            email: this.state.email,
            fingerprint: data.visitorId,
          },
        };

        usersAuthLog(authLogObjOne);

        this.setState({
          loading: true,
        });
        const start = performance.now();
        axios
          .post(get_host(password_forgot_endpoint), formData)
          .then((res) => {
            if (res.status === 200) {
              this.setState({
                email_sent: true,
                err_message: "",
                loading: false,
              });

              const authLogObj = {
                email: this.state.email,
                serviceCategory: "Auth",
                service: "Login",
                eventCategory: "Forgot Password",
                eventAction: "Submit",
                eventLabel: "Password Reset Link Sent",
                fingerprintID: data.visitorId,
                eventValue: {
                  status_code: res.status,
                  time_taken: performance.now() - start,
                  fingerprint: data.visitorId,
                },
              };

              usersAuthLog(authLogObj);
            }
          })
          .catch((err) => {
            this.setState({
              err_message:
                err.response && err.response.data !== undefined
                  ? err.response.data.detail || err.response.data.desccription
                  : err.message,
              loading: false,
            });

            const authLogObj = {
              email: this.state.email,
              serviceCategory: "Auth",
              service: "Login",
              eventCategory: "Forgot Password",
              eventAction: "Submit",
              eventLabel: "Invalid Email",
              fingerprintID: data.visitorId,
              eventValue: {
                status_code: err.response.status,
                time_taken: performance.now() - start,
                fingerprint: data.visitorId,
              },
            };

            usersAuthLog(authLogObj);
          });
      }
    });
  }

  getEmail({ value }) {
    this.setState({ email: value, err_message: "" });
  }

  handleError = (errorState, er) => {
    let x;
    this.setState(
      (state) => {
        x = state.error;
        x[errorState] = er;
        return { error: x };
      },
      () => {
        let flag;
        for (x in this.state.error) {
          if (this.state.error[x]) {
            flag = true;
          }
        }
        if (flag === true) {
          this.setState({ finalError: true });
        } else {
          this.setState({ finalError: false });
        }
      }
    );
  };

  render() {
    const backendBroke =
      "Something seems to be broken. Please try after some time.";
    const errorText =
      "There was an error with your e-mail/password combination. Please try again.";
    return (
      <Template>
        <div className="login-welcome-container" style={{ display: "grid" }}>
          <div className="header-container">
            <span className="login-welcome-text">
              Welcome to Consumer Intelligence
            </span>
          </div>
          <div className="" style={{ maxWidth: "368px", width: "100%" }}>
            {this.state.backendBroke ? (
              <span className="error-msg" style={{ textAlign: "start" }}>
                {backendBroke}
              </span>
            ) : (
              ""
            )}
            {this.state.wrongPassword ? (
              <span className="error-msg" style={{ textAlign: "start" }}>
                {errorText}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <br />
        <div /* style={{width:"95%"}} */ onKeyDown={this.handlekeyPress}>
          <Input
            className="input.button"
            leftChild={(() => (
              <img
                alt=""
                className="icon"
                src={require("./../../assests/mail.svg")}
              />
            ))()}
            label="EMAIL"
            placeholder="Enter your work email address"
            value={this.state.email}
            onChange={(e) => this.handleChangeemail(e)}
            validation="email"
            errorMessage="Enter a valid email address"
            getInputValue={this.getEmail}
            error={this.state.error}
            disabled={!!this.state.email_sent}
            handleError={this.handleError}
            type={this.state.inputEmailType}
            name={this.state.inputEmailType}
          />
          {this.state.err_message && (
            <small style={{ color: "red" }}>{this.state.err_message}</small>
          )}
          {this.state.email_sent ? (
            <div className="check_email_container">
              <Lottie
                style={{ height: "111px" }}
                animationData={astronaut}
                loop
              />
              <div className="check_email">Check Email!!</div>
              <div className="signed_in_for_two_weeks">
                Click on the link and reset your password
              </div>
            </div>
          ) : (
            <div className="options-buttons-container">
              <div className="login-button without-password">
                {this.state.loading ? (
                  <button
                    type="button"
                    className="inactive-button"
                    disabled
                    style={{ backgroundColor: "#479AFF" }}
                  >
                    Please wait...
                  </button>
                ) : this.state.finalError ? (
                  <button type="button" className="inactive-button">
                    Reset
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={this.handleSubmit}
                    className={
                      this.state.email !== ""
                        ? "without-password click-ripple-effect"
                        : "inactive-button"
                    }
                    disabled={this.state.email === ""}
                    style={{
                      backgroundColor:
                        this.state.email === "" ? "grey" : "#479AFF",
                    }}
                  >
                    Reset password
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="login-contact-us-container">
          <div>
            New to Stylumia?&nbsp;{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.stylumia.ai/get-a-demo/"
            >
              {" "}
              Sign up for a demo{" "}
            </a>
          </div>
        </div>
      </Template>
    );
  }
}

function CallApi(Component) {
  return function WrappedComponent(props) {
    const { getData } = useVisitorData(
      { extended: true },
      { immediate: false }
    );
    return <Component {...props} fpGetData={getData} />;
  };
}

export default CallApi(ForgotPassword);
