/*
 * File: index.js
 * Project: frontend
 * File Created: Wednesday, 3rd April 2024 5:12:28 pm
 * Author: Sharath Puranik (sharath.puranik@stylumia.com)
 * -----
 * Last Modified: Friday, 30th August 2024 1:03:23 am
 * Modified By: Sharath Puranik (sharath.puranik@stylumia.com>)
 * -----
 * Copyright - 2024, Stylumia Intelligence Technology Pvt Ltd
 */
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
import { CacheLocation } from "@fingerprintjs/fingerprintjs-pro-spa";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

require("dotenv").config();

ReactDOM.render(
  <HashRouter>
    <FpjsProvider
      cacheLocation={CacheLocation.LocalStorage}
      cachePrefix="FINGERPRINT"
      cacheTimeInSeconds={60 * 60 * 24}
      loadOptions={{
        apiKey: process.env.REACT_APP_FINGERPRINT_KEY,
        scriptUrlPattern: process.env.REACT_APP_FINGERPRINT_SCRIPT,
        endpoint: process.env.REACT_APP_FINGERPRINT_ENDPOINT,
      }}
    >
      <App />
    </FpjsProvider>
  </HashRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
