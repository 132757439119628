/* eslint-disable no-console */
import axios from "axios";

import get_host from "../utils";

export const usersAuthLog = async (payload) => {
  const timestamp = Date.now();
  const date = new Date().toISOString();

  const meta = {
    referer: document.referrer,
    url: window.location.href,
    host: window.location.host,
    agent: window.navigator.userAgent,
    platform: window.navigator.platform,
    // navigator?.userAgentData?.platform || navigator?.platform || 'unknown',
    email: payload.email,
    fingerprintID: payload.fingerprintID,
    date,
    timestamp,
  };

  const AuthPayload = {
    timestamp,
    date,
    serviceCategory: payload.serviceCategory,
    service: payload.service,
    eventCategory: payload.eventCategory,
    eventAction: payload.eventAction,
    eventValue: payload.eventValue,
    eventLabel: payload.eventLabel,
    meta,
  };

  logESRecord(AuthPayload);
};

export const logESRecord = async (payload) => {
  try {
    await axios.post(`${get_host("/api/logging/es_log")}`, { ...payload });
  } catch (error) {
    console.log(error);
  }
};
