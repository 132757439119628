import React, { PureComponent } from "react";

// import NeedHelp from '../../../../components/needHelp';
import "./style.css";

export default class extends PureComponent {
  render() {
    // let needHelpConfig = {};
    // // default is for signin Page
    // if (window.location.pathname.includes('signup')) {
    //   needHelpConfig = {
    //     top: '540px',
    //     left: 'initial',
    //   };
    // } else {
    //   needHelpConfig = {
    //     top: '360px',
    //     left: 'initial',
    //   };
    // }

    // let NeedHelpWidth = '368px';
    // if (window.innerWidth < 580) {
    //   NeedHelpWidth = 'auto';
    // }
    return (
      <div className="login-footer-main">
        <hr className="footer-line-sep" />
        <div className="login-footer-menu">
          {/* <NeedHelp top={needHelpConfig.top} left={needHelpConfig.left} width={NeedHelpWidth} /> */}
          <div className="login-footer-submenu">
            <div>
              {" "}
              <a
                href="https://www.stylumia.ai/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy{" "}
              </a>
            </div>
            <div>
              {" "}
              <a
                href="https://www.stylumia.ai/terms-conditions/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Terms{" "}
              </a>
            </div>
            {/* <div> <a href="#" class="disabled">Cookie Policy </a></div> */}
          </div>
        </div>
        <div className="login-footer-copyright">
          <div>
            © {new Date().getFullYear()} Stylumia Intelligence Technology Pvt
            Ltd
          </div>
        </div>
      </div>
    );
  }
}
