import React, { PureComponent } from "react";
import "./style.css";

export default class Header extends PureComponent {
  render() {
    return (
      <div className="login-header">
        <div className="login-logo">
          <img
            id="login-logo-image"
            src={require("./../../../../assests/stylumia_logo.svg")}
            alt=""
          />
        </div>
      </div>
    );
  }
}
