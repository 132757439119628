/*
 * File: ResetPassword.jsx
 * Project: frontend
 * File Created: Monday, 1st May 2023 5:07:21 pm
 * Author: Sharath Puranik (sharath.puranik@stylumia.com)
 * -----
 * Last Modified: Friday, 30th August 2024 1:12:56 am
 * Modified By: Sharath Puranik (sharath.puranik@stylumia.com>)
 * -----
 * Copyright - 2024, Stylumia Intelligence Technology Pvt Ltd
 */

import React, { Component } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

import {
  password_reset_endpoint,
  password_validate,
} from "../../constants/constants";
import get_host from "../../utils";
import sadDog from "../../animations/sad-dog.json";
import { usersAuthLog } from "../../utils/loginLogs";
import Input from "../Input/index";

import Template from "./template";
import "./style.css";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    const search = props.location.search;
    const email = new URLSearchParams(search).get("email");
    const token = new URLSearchParams(search).get("token");
    const redirect = new URLSearchParams(search).get("redirect");

    this.state = {
      email,
      token,
      redirect,
      password: "",
      finalError: false,
      confirmPassword: "",
      inputPasswordType: "password",
      inputPasswordIcon: "showPassword.svg",
      passwordResetMessage: "",
      mainLoading: 0,
      status_code: "",
      backendBroke: false,
      error: { email: false, text: false },
      loading: false,
    };

    this.getPassword = this.getPassword.bind(this);
    this.getConfirmPassword = this.getConfirmPassword.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleGoToMainLogin = this.handleGoToMainLogin.bind(this);
  }

  handleGoToMainLogin() {
    this.props.history.push(`/?redirect=${this.state.redirect}`);
  }

  componentDidMount() {
    const fingerID = localStorage.getItem("fingerId");

    const formData = new FormData();
    formData.append("email", this.state.email);
    formData.append("redirect", this.state.redirect);
    formData.append("token", this.state.token);
    formData.append("fingerprint", fingerID);

    const start = performance.now();
    this.setState({
      mainLoading: 1,
    });
    axios
      .post(get_host(password_validate), formData)
      .then((res) => {
        // console.log(res)
        if (res.status === 200) {
          this.setState({
            mainLoading: 2,
            status_code: res.status,
          });

          const authLogObj = {
            email: this.state.email,
            serviceCategory: "Auth",
            service: "Login",
            eventCategory: "Reset password",
            eventAction: "Validate",
            eventLabel: "Password Reset Link Validated",
            fingerprintID: fingerID,
            eventValue: {
              status_code: res.status,
              time_taken: performance.now() - start,
              fingerprint: fingerID,
            },
          };

          usersAuthLog(authLogObj);
        }
      })
      .catch((err) => {
        // console.log(err)
        this.setState({
          mainLoading: 3,
          status_code: err.response.status,
        });

        if (err.response.status === 401 || 400) {
          const authLogObj = {
            email: this.state.email,
            serviceCategory: "Auth",
            service: "Login",
            eventCategory: "Reset password",
            eventAction: "Validate",
            eventLabel: `Password Reset Link Expired`,
            fingerprintID: fingerID,
            eventValue: {
              status_code: err.response.status,
              time_taken: performance.now() - start,
              fingerprint: fingerID,
            },
          };

          usersAuthLog(authLogObj);
        }
        if (err.response.status === 409) {
          const authLogObj = {
            email: this.state.email,
            serviceCategory: "Auth",
            service: "Login",
            eventCategory: "Reset password",
            eventAction: "Validate",
            eventLabel: `Different Browser/Device Detected`,
            fingerprintID: fingerID,
            eventValue: {
              status_code: err.response.status,
              time_taken: performance.now() - start,
              fingerprint: fingerID,
            },
          };

          usersAuthLog(authLogObj);
        }
      });
  }

  handleError = (errorState, er) => {
    let x;
    this.setState(
      (state) => {
        x = state.error;
        x[errorState] = er;
        return { error: x };
      },
      () => {
        let flag;
        for (x in this.state.error) {
          if (this.state.error[x]) {
            flag = true;
          }
        }
        if (
          flag === true ||
          this.state.password !== this.state.confirmPassword
        ) {
          this.setState({ finalError: true });
        } else {
          this.setState({ finalError: false });
        }
      }
    );
  };

  getPassword({ value }) {
    this.setState({ password: value });
  }

  getConfirmPassword({ value }) {
    this.setState({ confirmPassword: value });
  }

  showPassword() {
    if (this.state.inputPasswordType === "password") {
      this.setState({
        inputPasswordType: "text",
        inputPasswordIcon: "show-password-enabled.svg",
      });
    } else {
      this.setState({
        inputPasswordType: "password",
        inputPasswordIcon: "showPassword.svg",
      });
    }
  }

  handleSubmitLogin(event) {
    this.props
      .fpGetData()
      .then((fingerprintData) => {
        const data = fingerprintData;
        if (data && data.visitorId && this.state.email && this.state.password) {
          const formData = new FormData();
          formData.append("email", this.state.email);
          formData.append("redirect", this.state.redirect);
          formData.append("password", this.state.password);
          formData.append("token", this.state.token);
          formData.append("fingerprint", data.visitorId);

          this.setState({
            loading: true,
          });
          const start = performance.now();
          axios
            .post(get_host(password_reset_endpoint), formData)
            .then((res) => {
              if (res.status === 200) {
                // console.log(res)
                this.setState({
                  err_message: "",
                  loading: false,
                  passwordResetMessage: "password reset successfully.",
                });

                const authLogObj = {
                  email: this.state.email,
                  serviceCategory: "Auth",
                  service: "Login",
                  eventCategory: "Reset password",
                  eventAction: "Submit",
                  eventLabel: "Password Reset Successful",
                  fingerprintID: data.visitorId,
                  eventValue: {
                    status_code: res.status,
                    time_taken: performance.now() - start,
                    fingerprint: data.visitorId,
                  },
                };

                usersAuthLog(authLogObj);

                this.props.history.push(
                  `/login/password-reset-success?email=${this.state.email}&redirect=${this.state.redirect}`
                );
              }
            })
            .catch((err) => {
              // console.log(err)
              this.setState({
                err_message:
                  err.response && err.response.data !== undefined
                    ? err.response.data.detail || err.response.data.desccription
                    : err.message,
                loading: false,
                passwordResetMessage:
                  "There was some problem while resetting the password.",
              });

              const authLogObj = {
                email: this.state.email,
                serviceCategory: "Auth",
                service: "Login",
                eventCategory: "Reset password",
                eventAction: "Submit",
                eventLabel: "Password Reset Link Expired",
                fingerprintID: data.visitorId,
                eventValue: {
                  status_code: err.response.status,
                  time_taken: performance.now() - start,
                  fingerprint: data.visitorId,
                },
              };

              usersAuthLog(authLogObj);
            });
        }
      })
      .catch((error) => {
        const fingerprintData = { visitorId: "NA" };
        // run_auth(fingerprintData);
      });
  }

  handleResetPassword() {
    const search = this.props.location.search;
    const redirect = new URLSearchParams(search).get("redirect");
    this.props.history.push(`/login/forgot?redirect=${redirect}`);
  }

  handleRedirect = () => {
    this.props.history.push(
      `/login/magic?email=${this.state.email}&redirect=${this.state.redirect}`
    );
  };

  render() {
    const backendBroke =
      "Something seems to be broken. Please try after some time.";
    const errorText =
      "There was an error with your e-mail/password combination. Please try again.";
    return (
      <>
        {this.state.mainLoading === 1 ? (
          <div className="hourglass-loader">
            <img src="filter_hourglass.gif" alt="" />
          </div>
        ) : this.state.mainLoading === 2 ? (
          <Template>
            <div
              className="login-welcome-container"
              style={{ display: "grid" }}
            >
              <div className="header-container">
                <span className="login-welcome-text">Reset your Password</span>
              </div>
              <div className="" style={{ maxWidth: "368px", width: "100%" }}>
                {this.state.backendBroke ? (
                  <span className="error-msg" style={{ textAlign: "start" }}>
                    {backendBroke}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <br />
            <div>
              <Input
                leftChild={(() => (
                  <img
                    alt=""
                    className="icon"
                    src={require("./../../assests/lock.png")}
                  />
                ))()}
                rightChild={(() => (
                  <img
                    alt=""
                    onClick={this.showPassword}
                    className="icon show-password"
                    src={require(`./../../assests/${this.state.inputPasswordIcon}`)}
                  />
                ))()}
                label="PASSWORD"
                placeholder="Enter your password"
                value={this.state.password}
                validation="text"
                errorMessage="Password should be atleast 5 characters long"
                getInputValue={this.getPassword}
                type={this.state.inputPasswordType}
                error={this.state.error}
                handleError={this.handleError}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.handleSubmitLogin();
                  }
                }}
              />

              <Input
                leftChild={(() => (
                  <img
                    alt=""
                    className="icon"
                    src={require("./../../assests/lock.png")}
                  />
                ))()}
                rightChild={(() => (
                  <img
                    alt=""
                    onClick={this.showPassword}
                    className="icon show-password"
                    src={require(`./../../assests/${this.state.inputPasswordIcon}`)}
                  />
                ))()}
                label="RE-ENTER PASSWORD"
                placeholder="Re-enter your password"
                value={this.state.confirmPassword}
                validation="text"
                errorMessage="Password should be atleast 5 characters long"
                getInputValue={this.getConfirmPassword}
                type={this.state.inputPasswordType}
                error={this.state.error}
                handleError={this.handleError}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.handleSubmitLogin();
                  }
                }}
              />
              {this.state.err_message && (
                <small style={{ color: "red" }}>{this.state.err_message}</small>
              )}

              <div className="login-button ">
                {this.state.loading ? (
                  <button
                    type="button"
                    className="inactive-button"
                    disabled
                    style={{ backgroundColor: "#479AFF" }}
                  >
                    Resetting..
                  </button>
                ) : this.state.finalError ? (
                  <button type="button" className="inactive-button">
                    Reset
                  </button>
                ) : (
                  <button
                    type="button"
                    className={
                      this.state.confirmPassword !== "" ||
                      this.state.password !== ""
                        ? "without-password click-ripple-effect"
                        : "inactive-button"
                    }
                    disabled={
                      !(
                        this.state.confirmPassword !== "" ||
                        this.state.password !== ""
                      )
                    }
                    style={{
                      backgroundColor:
                        this.state.confirmPassword === "" ||
                        this.state.password === ""
                          ? "grey"
                          : "#479AFF",
                    }}
                    onClick={this.handleSubmitLogin}
                  >
                    Reset
                  </button>
                )}
              </div>
              {this.state.passwordResetMessage !== "" && (
                <div style={{ margin: "10px" }}>
                  <small>{this.state.passwordResetMessage}</small>
                </div>
              )}
              {/* <div className='forgot-password'>
              <span className='show-password' onClick={this.handleResetPassword}>Forgot password</span>
            </div> */}

              <button
                type="button"
                disabled={!!this.state.loading}
                onClick={this.handleGoToMainLogin}
                className={
                  this.state.loading
                    ? "inactive-button-password"
                    : "go-passwordless"
                }
              >
                <img style={{ marginRight: "10px" }} src="stars.svg" alt="" />
                Go Passwordless
                <span> Never remember a password again</span>
              </button>
            </div>
            <div className="login-contact-us-container">
              <div>
                New to Stylumia?&nbsp;{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.stylumia.ai/get-a-demo/"
                >
                  {" "}
                  Sign up for a demo{" "}
                </a>
              </div>
            </div>
          </Template>
        ) : this.state.mainLoading === 3 ? (
          <div className="login_unsuccessful">
            <Lottie style={{ height: "170px" }} animationData={sadDog} loop />
            {this.state.status_code === 409 ? (
              <>
                <h2 className="login-page-message-header">
                  Different device / browser detected
                </h2>
                <p>
                  Please ensure you're opening the link on the same browser /
                  device from where it was requested.
                </p>
                <button
                  type="button"
                  onClick={this.handleRedirect}
                  className="request-again-btn click-ripple-effect"
                  style={{ backgroundColor: "#479AFF" }}
                >
                  Try Again
                </button>
              </>
            ) : this.state.status_code === 401 || 400 ? (
              <>
                <h2 className="login-page-message-header">
                  Oops, link expired
                </h2>
                <p>
                  Kindly request again and click on the link within 15 minutes
                </p>
                {/* <p style={{ color: 'rgba(124, 124, 150, 1)' }}>{email}</p> */}
                <button
                  type="button"
                  onClick={this.handleRedirect}
                  className="request-again-btn click-ripple-effect"
                  style={{ backgroundColor: "#479AFF" }}
                >
                  Request Again
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

function CallApi(Component) {
  return function WrappedComponent(props) {
    const { getData } = useVisitorData(
      { extended: true },
      { immediate: false }
    );
    return <Component {...props} fpGetData={getData} />;
  };
}

export default CallApi(ResetPassword);
